<div class="dialog-container">
  <div class="title">
    <h3>Connect Wallet</h3>
    <button class="btn-close"
            [mat-dialog-close]="true"></button>
  </div>
  <div class="wallet-types">
    <label *ngFor="let walletType of typesToWallets" [class.active]="selectedType == walletType.type"
           (click)="selectWallet(walletType.type)">{{walletType.type}}</label>
  </div>
  <div>
    <div *ngFor="let walletProvider of availableWallets" class="wallet-provider"
         (click)="connectWallet(walletProvider.name)">
      <img *ngIf="walletProvider.icon" src="{{walletProvider.icon}}"/>
      {{walletProvider.name}}
    </div>
  </div>
</div>
