export enum WalletProviders {
  WalletConnect = 'WalletConnect',
  Metamask = 'Metamask',
  Phantom = 'Phantom',
  Sollet = 'Sollet',
  Solflare = 'Solflare',
  Maiar = 'Maiar',
  TonKeeper = 'TonKeeper',
  TonConnect = 'TonConnect'
}
