import { IWalletType, WalletTypes } from "./wallet-type.model";
import { WalletProviders } from "./wallet-providers.enum";

export const typesToWallets: IWalletType[] = [{
  type: WalletTypes.EVM,
  wallets: [
    {
      name: WalletProviders.Metamask,
      icon: 'assets/images/image 1.png'
    },
    /*{
      name: WalletProviders.WalletConnect,
      icon: 'assets/images/WalletConnect.png'
    }*/
  ]
},
  {
    type: WalletTypes.Solana,
    wallets: [
      /*{
        name: WalletProviders.Sollet,
        icon: 'assets/images/Sollet.png'
      },*/
      /*{
        name: WalletProviders.Solflare,
        icon: 'assets/images/Solflare.png'
      },*/
      {
        name: WalletProviders.Phantom,
        icon: 'assets/images/Phantom.png'
      }
    ]
  },
  {
    type: WalletTypes.TON,
    wallets: [
      {
        name: WalletProviders.TonKeeper,
        icon: 'assets/images/tonkeeper.png'
      },
      {
        name: WalletProviders.TonConnect,
        icon: 'assets/images/tonconnect.png'
      }
    ]
  },
  /*{
    type: WalletTypes.Elrond,
    forAdmin: false,
    wallets: [
      {
        name: WalletProviders.Maiar,
        icon: 'assets/images/maiar.png'
      }
    ]
  }*/
];
