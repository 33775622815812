import {Component, Input, Output, EventEmitter, OnDestroy} from '@angular/core';

@Component({
    selector: 'app-alert',
    templateUrl: './alert.component.html',
    styleUrls: ['./alert.component.scss'],
})
export class AlertComponent implements OnDestroy {
    @Input() icon: string = '';
    @Input() title: string = '';
    @Input() message: string = '';
    @Input() innerHTML: string = '';
    @Input() buttonOkLabel: string = '';

    @Output() close: EventEmitter<any> = new EventEmitter();

    constructor() {}

    onContinue() {
        this.close.emit();
    }

  ngOnDestroy(): void {
      this.close?.complete();
  }
}
