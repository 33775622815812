import {Injectable, NgZone} from "@angular/core";
import {BehaviorSubject, combineLatest, Observable} from "rxjs";
import {environment} from "src/environments/environment";
import Web3 from "web3";
import {MerkleTree} from "merkletreejs";
import marketplaceAbi from "../../assets/abi/marketplaceABI.json";
import gmpdNftCollectionABI from "../../assets/abi/gmpd_nft_collectionABI.json";
import erc20ABI from "../../assets/abi/erc20ABI.json";
import ClaimingABI from "../../assets/abi/claimingcontract.json";
import ClaimingABIV2 from "../../assets/abi/v2claimingcontract.json";
import ClaimingABIV3 from "../../assets/abi/v3claimingcontract.json";
import {UnlockPeriodModel} from "../models/unlock-period.model";
import {map} from "rxjs/operators";
import {AlertService} from "./alert.service";
import WalletConnectProvider from "@walletconnect/web3-provider";
import {EventsHandler} from "./web3/events.handler";
import BigNumber from "bignumber.js";
import {AuthService} from "./auth.service";

declare let window: any;

export const EVM_TOKEN_PERCENTAGE_DECIMAL = 100000000;
// import collectionABI from '@/abi/collection.json';

@Injectable({ providedIn: 'root' })
export class Web3Service {
  private walletConnectProvider: WalletConnectProvider;

  public params(data: any): any {
    return { ...data, maxPriorityFeePerGas: null, maxFeePerGas: null };
  }

    public async changeNetwork(id: number): Promise<void> {
      if (window.ethereum && !this.walletConnectProvider?.connected) {
        try {
          await window.ethereum.request({
            method: 'wallet_switchEthereumChain',
            params: [{chainId: this.web3Instance.utils.toHex(id)}],
          });
        } catch (switchError: any) {
          console.log(switchError);
          if (switchError.code === 4902) {
            //alert('add this network');
            try {
              await window.ethereum.request({
                method: 'wallet_addEthereumChain',
                params: [environment.networkData.filter(i => i.chainId == `0x${(id).toString(16)}`)[0]],
              });
            } catch (addError) {
              console.error(addError);
            }
          }
        }
      } else if (this.walletConnectProvider?.connected) {
        const connector = await this.walletConnectProvider.getWalletConnector();
        connector.updateSession({chainId: id, accounts: [this.currentAccountValue]});
      } else {
        this.alertService.show('Please install Metamask extension to change network');
      }
    }

    public isConnected: boolean = false;

    public get currentAccountValue(): string {
        return this.currentAccount.value;
    };

    public get currentNetworkValue(): string {
        return this.currentNetwork.value;
    }

    public get currentNetworkDecimalValue(): number {
      return parseInt(this.currentNetwork.value, 16);
    }

    private _instance: Web3;
    public get web3Instance(): Web3 {
        if (!this._instance) {
            this._instance = new Web3(window.ethereum);
        }
        return this._instance;
    }

    public set web3Instance(web3: Web3 ) {
      this._instance = web3;
    }

    private readonly currentAccount = new BehaviorSubject<string>('');
    public readonly currentAccount$ = this.currentAccount.asObservable();

    private readonly currentNetwork : BehaviorSubject<string> = new BehaviorSubject<string>(environment.defaultChainId);
    public readonly currentNetwork$ = this.currentNetwork.asObservable();
    public readonly currentNetworkDecimal$ : Observable<number> = this.currentNetwork$.pipe(map((i: any)=> parseInt(i, 16)));


    public readonly sensetiveDataChanged$: Observable<[string, string]> = combineLatest([this.currentAccount$, this.currentNetwork$]);


    constructor(private readonly ngZone: NgZone,
                private readonly eventsHandler: EventsHandler,
                private readonly alertService: AlertService,
                private readonly authService: AuthService) {
        const account = localStorage.getItem('accountAddress');
        const network = localStorage.getItem('networkId');
        const walletConnectConfig = localStorage.getItem('walletconnect');
        if (account && network && walletConnectConfig) {
          this.createWalletConnectProvider(walletConnectConfig);
          this.walletConnectProvider.enable().then(()=>{
            this.web3Instance = new Web3(this.walletConnectProvider as any);
          });
          this.initWallet(account, network);
        }
        if (account && network) {
          this.initWallet(account, network);
        }

      this.eventsHandler.accountChange$.subscribe(acc=>{
        this.setAccount(acc);
        location.reload();
        // this.logout();
      });
      this.eventsHandler.networkChange$.subscribe(chainIdHex =>{
        this.setNetwork(chainIdHex);
      });
    }

    public createWalletConnectProvider(config: string = null): WalletConnectProvider {
      const rpcObject = {} as any;
      environment.networkData.map(network => {
        rpcObject[parseInt(network.chainId, 16)] = network.rpcUrls[0];
      });

      this.walletConnectProvider = new WalletConnectProvider({
        ...JSON.parse(config),
        rpc: rpcObject
      });
      this.walletConnectProvider.chainId = this.currentNetworkDecimalValue;
      this.eventsHandler.addProviderEvents(this.walletConnectProvider);

      return this.walletConnectProvider;
    }

    public async getTokenInfo(tokenAddress:string, address: string = this.currentAccountValue): Promise<{tokenDecimal: number, tokenName:string, tokenSymbol:string, totalSupply: string, balance:string}>{
        try{
            let contract = new this.web3Instance.eth.Contract(erc20ABI.abi as any, tokenAddress);
            const tokenDecimal = await contract.methods.decimals().call();
            const tokenName = await contract.methods.name().call();
            const tokenSymbol = await contract.methods.symbol().call();
            const totalSupply = await contract.methods.totalSupply().call();
            const balance = await contract.methods.balanceOf(address).call();
            return {tokenDecimal, tokenName, tokenSymbol, totalSupply, balance}
        }
        catch(e:any){
            return null;
        }
    }

    public getPosition(positionId: number): Promise<any> {
        return new Promise((resolve, reject) => {
            let contract = new this.web3Instance.eth.Contract(marketplaceAbi.abi as any, environment.marketplaceContract);
            contract.methods.positions(positionId).call({}, (error: any, resp: any) => {
                console.log("positionId resp", resp);
                resolve(resp);
            });
        }) as Promise<any>;
    }

    public async putNFTOnSale(tokenId: number, tokenType: any, amount: any, collection: string, currency: string, price: any): Promise<any> {
        const marketplaceContract = new this.web3Instance.eth.Contract(
            marketplaceAbi.abi as any,
            environment.marketplaceContract
        );

        const collectionContract = new this.web3Instance.eth.Contract(gmpdNftCollectionABI.abi as any, environment.GMPD_NFT);

        const isApproved = await collectionContract.methods
            .isApprovedForAll(this.currentAccountValue, environment.marketplaceContract)
            .call();

        if (!isApproved) {
            await collectionContract.methods.setApprovalForAll(environment.marketplaceContract, true).send(this.params({ from: this.currentAccountValue }));
        }

        return marketplaceContract.methods
            .putOnSale(
                collection,
                tokenType,
                tokenId,
                amount.toString(10),
                price.toString(10),
                currency
            )
            .send(this.params({ from: this.currentAccountValue }));
    }


    public async cancelSale(positionId: number): Promise<any> {
        const marketplaceContract = new this.web3Instance.eth.Contract(
            marketplaceAbi.abi as any,
            environment.marketplaceContract
        );

        return marketplaceContract.methods.cancel(positionId.toString(10)).send(this.params({ from: this.currentAccountValue }));
    }

    public async buyToken(positionId: number, currenty:string, amount: number = 1): Promise<any> {

        let contract = new this.web3Instance.eth.Contract(erc20ABI.abi as any, currenty);

        await contract.methods
            .approve(environment.marketplaceContract, '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff')
            .send(this.params({ from: this.currentAccountValue }));

        const marketplaceContract = new this.web3Instance.eth.Contract(
            marketplaceAbi.abi as any,
            environment.marketplaceContract
        );
        return marketplaceContract.methods
            .buy(positionId.toString(10), amount.toString(10), this.currentAccountValue, 0)
            .send(this.params({ from: this.currentAccountValue }));

    }


    public initWallet(address: string, network: string, walletName: string = null): void {
        this.setAccount(address);
        this.setNetwork(network);
        this.eventsHandler.addProviderEvents(this.web3Instance.eth.givenProvider);
        this.isConnected = true;
    }

    public async personalSign(dataToSign: string, address: string): Promise<string> {
      return this.web3Instance.eth.personal.sign(dataToSign, address, '');

    }

    public logout(): void{
      this.authService.logout();
      localStorage.removeItem('walletconnect');
      this.setAccount('');
      this.isConnected = false;
      this._instance = null;
    }

    private setAccount(account: string): void {
        this.ngZone.run(() => {
            this.currentAccount.next(account);
            if (!account){
              localStorage.removeItem('accountAddress');
            }
            else {
              localStorage.setItem('accountAddress', account);
            }
        })

    }

    private setNetwork(networkId: string): void {
        this.ngZone.run(() => {
            this.currentNetwork.next(networkId);
            localStorage.setItem('networkId', networkId);
        })

    }


    private getIndexOfK(arr:string[][], k:string) {
      for (var i = 0; i < arr.length; i++) {
        const value = arr[i][0].toLowerCase();
        if (value == k.toLowerCase()) {
          return i;
        }
      }
      return -1;
    }

    private getClaimingAbi(version: number): any {
      switch (version) {
        case 2:
          return ClaimingABIV3;
        case 1:
          return ClaimingABIV2;
        default:
          return ClaimingABI;
      }
    }

    //#region ClaimingAbi

    private arrayToHex(data:any):string{
      return `0x${Array.from(data, (byte:number) => {
        return ('0' + (byte & 0xFF).toString(16)).slice(-2);
      }).join('')}`
    }

    public addTokenToMetamask(tokenAddress:string, tokenSymbol: string, tokenDecimals: number){
      window.ethereum.request({
        method: 'wallet_watchAsset',
        params: {
          type: 'ERC20', // Initially only supports ERC20, but eventually more!
          options: {
            address: tokenAddress, // The address that the token is at.
            symbol: tokenSymbol, // A ticker symbol or shorthand, up to 5 chars.
            decimals: tokenDecimals, //lock.tokenDecimals, // The number of decimals in the token
            //image: tokenImage, // A string url of the token logo
          },
        },
    })
  }

    public async getAvailableTokens(version: number, totalAllocation: string, claimingContractAddress:string, proof: string[]) : Promise<string> {
        const contract = new this.web3Instance.eth.Contract(this.getClaimingAbi(version), claimingContractAddress);
        return contract.methods
            .getAvailableTokens(totalAllocation, this.currentAccountValue, proof)
            .call();
    }

    public async getTotalClaimedPerWallet(version: number, address: string, totalAllocation: string, claimingContractAddress:string, proof: string[], walletAddress: string = this.currentAccountValue, ) : Promise<string> {
        const contract = new this.web3Instance.eth.Contract(this.getClaimingAbi(version), claimingContractAddress);
        return contract.methods
            .getTotalClaimedPerWallet(address, totalAllocation, proof)
            .call();
    }

    public async getIfVestingStopped(version: number, contractAddress: string): Promise<boolean> {
      const contract = new this.web3Instance.eth.Contract(this.getClaimingAbi(version), contractAddress);
      return contract.methods.isVestingStopped().call();
    }

    public async depositFundsToLock(contractAddress: string, tokenAddress:string, amount:BigNumber): Promise<any>{
      const contract = new this.web3Instance.eth.Contract(erc20ABI.abi as any, tokenAddress);
      return contract.methods.transfer(contractAddress, amount.toString(10)).send(this.params({ from: this.currentAccountValue }));
    }

    public async addRefunded(contractAddress: string, addresses:string[]): Promise<any>{
      const contract = new this.web3Instance.eth.Contract(ClaimingABIV3 as any, contractAddress);
      return contract.methods.addRefunded(addresses).send(this.params({ from: this.currentAccountValue }));
    }

    public async emergencyWithdraw(version: number, contractAddress: string, amount: string):Promise<any>{
      const contract = new this.web3Instance.eth.Contract(this.getClaimingAbi(version), contractAddress);
      return contract.methods.withdrawEmergency(this.currentAccountValue, amount).send(this.params({ from: this.currentAccountValue }));
    }

    public async lock(version: number, tokenAddress: string, claimingWallets: string[][], unlocks: UnlockPeriodModel[], refundDate: number, claimingContractAddress:string) : Promise<any> {
        const walletsMerkleRoot = this.getMerkleRoot(claimingWallets);
        const totalAmount = claimingWallets.reduce((result: BigNumber, current: string[]) => {
          return result.plus(current[1]);
        }, new BigNumber(0));
        const contract = new this.web3Instance.eth.Contract(this.getClaimingAbi(version), claimingContractAddress);
        console.log('walletsMerkleRoot', walletsMerkleRoot, walletsMerkleRoot.toString('hex'));
        return contract.methods
            .lock(tokenAddress, walletsMerkleRoot, totalAmount.toString(10), unlocks, refundDate)
            .send(this.params({ from: this.currentAccountValue }));
    }

    public async claim(version: number, totalAllocation: string, /*claimingWallets: string[][],*/ claimingContractAddress:string, /*walletAddress: string = this.currentAccountValue*/ proof: string[]) : Promise<any> {
        //const proof = this.getMerkleProof(claimingWallets, this.getIndexOfK(claimingWallets, walletAddress));
        const contract = new this.web3Instance.eth.Contract(this.getClaimingAbi(version), claimingContractAddress);
      console.log('this.currentAccountValue', this.currentAccountValue, totalAllocation, proof)
        return contract.methods
            .claim(totalAllocation, proof)
            .send(this.params({ from: this.currentAccountValue }));
    }

    public async stopVesting(claimingContractAddress: string) : Promise<any> {
      const contract = new this.web3Instance.eth.Contract(ClaimingABIV2 as any, claimingContractAddress);
      return contract.methods.stopVesting().send(this.params({ from: this.currentAccountValue }));
    }

    public async updateWallet(version: number, totalAllocation: string, newWalletAddress: string,
                              claimingWallets: string[][], claimingContractAddress:string, originalWallet:string = this.currentAccountValue) : Promise<any> {
        const proof = this.getMerkleProof(claimingWallets, this.getIndexOfK(claimingWallets, originalWallet));
        const contract = new this.web3Instance.eth.Contract(this.getClaimingAbi(version), claimingContractAddress);

        return contract.methods
            .updateWallet(totalAllocation, newWalletAddress, proof)
            .send(this.params({ from: this.currentAccountValue }));
    }

    public async setRoot(version: number, tokenAddress: string, claimingWallets: string[][], claimingContractAddress:string) : Promise<any> {
        const walletsMerkleRoot = this.getMerkleRoot(claimingWallets);
        const contract = new this.web3Instance.eth.Contract(this.getClaimingAbi(version), claimingContractAddress);

        return contract.methods
            .setRoot(tokenAddress, walletsMerkleRoot)
            .send(this.params({ from: this.currentAccountValue }));
    }

    public async setPause(version: number, tokenAddress: string, paused: boolean, claimingContractAddress:string) : Promise<any> {
        const contract = new this.web3Instance.eth.Contract(this.getClaimingAbi(version), claimingContractAddress);

        return contract.methods
            .setPause(tokenAddress, paused)
            .send(this.params({ from: this.currentAccountValue }));
    }

    private getMerkleRoot(claimingWallets: string[][]) : Buffer {
        const merkleTree = this.getMerkleTree(claimingWallets);
        return merkleTree.getRoot();
    }

    public getMerkleProof(claimingWallets: string[][], index: number) : string[] {
        const merkleTree = this.getMerkleTree(claimingWallets);
        return merkleTree.getHexProof(merkleTree.getLeaf(index));
    }

    public getMerkleTree(claimingWallets: string[][]) : MerkleTree {
        const leafNodes = claimingWallets.map(([addr, value]) =>
            this.web3Instance.utils.keccak256(
                this.web3Instance.eth.abi.encodeParameters(["address", "uint256"], [addr, value])));
        const tree = new MerkleTree(leafNodes, this.web3Instance.utils.keccak256, { sortPairs: true });
        console.log('tree', tree);
        return tree;
    }

    public async requestRefund(claimingContractAddress: string): Promise<any> {
      const contract = new this.web3Instance.eth.Contract(ClaimingABIV3 as any, claimingContractAddress);
      return contract.methods.requestRefund().send(this.params({ from: this.currentAccountValue }));
    }

    public async revokeRefund(claimingContractAddress: string): Promise<any> {
      const contract = new this.web3Instance.eth.Contract(ClaimingABIV3 as any, claimingContractAddress);
      return contract.methods.revokeRefund().send(this.params({ from: this.currentAccountValue }));
    }

    public async exportRefundRequests(contractAddress: string): Promise<any> {
      const contract = new this.web3Instance.eth.Contract(ClaimingABIV3 as any, contractAddress);
      return contract.methods.getRefundRequested().call({ from: this.currentAccountValue });
    }

    public async isRefundRequested(contractAddress: string): Promise<boolean> {
      const contract = new this.web3Instance.eth.Contract(ClaimingABIV3 as any, contractAddress);
      try {
        return contract.methods.isRefundRequested().call({ from: this.currentAccountValue});
      } catch (error) {
        console.error('Error calling the contract method:', error);
        throw error;
      }
    }

    public async isRefunded(contractAddress: string): Promise<boolean> {
      const contract = new this.web3Instance.eth.Contract(ClaimingABIV3 as any, contractAddress);
      return contract.methods.isRefunded().call({ from: this.currentAccountValue });
    }

    public async vestingStoppedTime(version: number, contractAddress: string): Promise<number> {
      const contract = new this.web3Instance.eth.Contract(this.getClaimingAbi(version), contractAddress);
      return contract.methods.stoppedTime().call({ from: this.currentAccountValue });
    }

    //#endregion ClaimingAbi
}

// export function WalletAccountRequired(target: Object, propertyKey: string, descriptor: PropertyDescriptor) {
//     console.log('UnlockedWallet');
//     const originalMethod = descriptor.value;
//     descriptor.value = async function (...args: any) {
//         // const service = ExtraModuleInjector.get<UsersServiceProxy>(UsersServiceProxy);
//         const unlockWalletResult = await window.ethereum.request({ method: 'eth_requestAccounts' });
//         console.log("unlockWalletResult", unlockWalletResult);
//         if (unlockWalletResult.length > 0) {
//             console.log('originalMethod', originalMethod, [unlockWalletResult[0], ...args])
//             return originalMethod.apply(this, [unlockWalletResult[0], ...args]);
//         } else {
//             console.log("not provided");
//             // swal.fire({
//             //     text: 'You must complete KYC form first.',
//             //     icon: 'warning',
//             // });
//         }
//     };
//     return descriptor;
// }
