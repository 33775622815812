import { Injectable } from '@angular/core';
import { LockDTO } from '../dto/lock.dto';
import swalert from 'sweetalert2';
import { Web3Service } from './web3.service';

declare let window: any;

@Injectable({ providedIn: 'root' })
export class PopupService {
  constructor(private readonly web3: Web3Service) {}

  public successMessage(text: string): void {
    this.showMessage('Success', text, 'success');
  }

  public errorMessage(text: string): void {
    this.showMessage('Error', text, 'error');
  }

  private showMessage(
    title: string,
    text: string,
    type: 'success' | 'error'
  ): void {
    swalert.fire(title, text, type);
  }

  public duplicateWalletWarning(): void {
    swalert.fire({
      icon: 'error',
      title: 'Duplicate wallet',
      text: 'This Wallet is already in use. Please choose a different wallet.',
      showConfirmButton: false,
      showCancelButton: true,
      cancelButtonText: 'Close',
      allowOutsideClick: false,
      allowEscapeKey: false,
    });
  }

  public changeWalletDisabled(): void {
    swalert.fire({
      icon: 'error',
      title: 'Change wallet temporarily disabled',
      text: 'We will turn it on as soon as possible.',
      showConfirmButton: false,
      showCancelButton: true,
      cancelButtonText: 'Close',
      allowOutsideClick: false,
      allowEscapeKey: false,
    });
  }

  public successClaimPopup(lock: LockDTO): void {
    swalert.fire({
      icon: 'success',
      title: 'Claim Successful',
      showConfirmButton: true,
      showCancelButton: true,
      cancelButtonText: 'Close',
      allowOutsideClick: false,
      allowEscapeKey: false,
      confirmButtonText: `Add ${lock.tokenSymbol} to Metamask <img src="assets/images/image 1.png" alt="">`,
      preConfirm: () => {
        this.web3.addTokenToMetamask(
          lock.tokenAddress,
          lock.tokenSymbol,
          lock.tokenDecimals
        );
        return false;
      },
    });
  }

  public async confirmWalletChange(): Promise<string> {
    return swalert
      .fire({
        title: 'Change Wallet',
        html: `
        You are about to change your receiving address. <br/>
        <strong style="font-size: 15px">Please double check your new wallet address before clicking Confirm!</strong> <br/>
        <strong>DO NOT USE EXCHANGE WALLET ADDRESSES OR YOU WILL NOT BE ABLE TO CLAIM YOUR TOKENS!</strong> <br/>
        BullPerks and GamesPad takes no responsibility for any loss incurred as a result of putting the wrong address.
        `,
        icon: 'warning',
        input: 'text',
        inputLabel: 'New Wallet',
        inputAttributes: {
          autocapitalize: 'off',
        },
        inputValidator: (wallet) => {
          if (!wallet) {
            return "Wallet address can't be empty";
          }
          return null;
        },
        showCancelButton: true,
        confirmButtonText: 'Confirm',
      })
      .then((result) => {
        console.log('result', result);
        if (result.isConfirmed) {
          return swalert
            .fire({
              html: `<strong>Are you sure that the wallet <span style="color: #fff">${result.value}</span> is correct?</strong>`,
              icon: 'warning',
              confirmButtonText: 'Submit',
              showCancelButton: true,
            })
            .then((res) => {
              if (res.isConfirmed) {
                return result.value;
              }
            });
        }
        return false;
      });
  }

  public async vestingCanceled(): Promise<boolean> {
    return swalert
      .fire({
        html: `
      This vesting was stopped. Please check our announcements channel for a refund announcement.
        `,
        icon: 'warning',
      })
      .then((result) => {
        console.log('result', result);
        if (result.isConfirmed) {
          return true;
        }
        return false;
      });
  }

  public async showClaimUpdateInfo(): Promise<boolean> {
    return swalert
      .fire({
        title: `
        <div class="swal2-icon swal2-warning swal2-icon-show" style="margin: 9px 10px 0px -180px; border:1px solid;width:16px; height:16px; display: flex; color: rgb(242, 116, 116); border-color: rgb(242, 116, 116);">
          <div class="swal2-icon-content" style="font-size:10px">!</div>
        </div>
        <span style="font-size:20px">Update</span>
      `,
        html: `
        <span style="color:#ece5e5">To claim your tokens please go to the Project's page.</span>
        `,
      })
      .then((result) => {
        if (result.isConfirmed) {
          localStorage.setItem('claimUpdate', '1');
          return true;
        }
        return false;
      });
  }

  public async whyCantClaim(percent?:number, date?:string, coin?: string, refundType?: string, isRefunded?: boolean): Promise<boolean> {
    const userAddress = this.web3.currentAccountValue;
    const usdtUrl = `https://bscscan.com/token/0x55d398326f99059ff775485246999027b3197955?a=${userAddress}`;
    const busdUrl = `https://bscscan.com/token/0xe9e7cea3dedca5984780bafc599bd69add087d56?a=${userAddress}`;
    return swalert
      .fire({
        title:
          '<div style="font-size: 20px;">If you can’t claim your tokens, please check that :</div>',
        html: `
        <div style="color: white; text-align: left; padding: 25px;">
          <div style="display: flex; ">
            <div style="width: 15px">1.</div><div> You connected the right wallet address to claim tokens. </div>
          </div>
          <div style="display: flex; ">
            <div style="width: 15px">2.</div><div> If you changed your wallet address, please connect the latest one. </div>
          </div>
          <div style="display: flex; ">
            <div style="width: 15px">3.</div><div> You are in the right network. </div>
          </div>
          <div style="display: flex; ">
            <div style="width: 15px">4.</div><div> You received a refund for the remaining distributions in USDT/BUSD (BEP-20) </div>
          </div>
          <div style="display: flex; ">
            <div style="width: 15px">5.</div><div> If it still doesn’t work, click on the button below to email support. </div>
          </div>
          ${(percent && date && coin && refundType == 'partial') ? `
            <div style="display: flex; ">
              <div style="width: 15px">6.</div><div> <a href="${coin.toLowerCase() == 'usdt' ? usdtUrl : busdUrl}" target="_blank">Your wallet received a ${percent}% refund in stablecoins on ${date} UTC</a> </div>
            </div>` : ``}
          ${(percent && date && coin && refundType == 'full' && isRefunded) ? `
            <div style="display: flex; ">
              <div style="width: 15px">6.</div><div> <a href="${coin.toLowerCase() == 'usdt' ? usdtUrl : busdUrl}" target="_blank">Your wallet received a ${percent}% refund in stablecoins on ${date} UTC</a> </div>
            </div>` : ``}
        </div>
        <a href="mailto:support@bullperks.com" target="_blank" style="text-decoration: none; color: white;">
          <div style="font-size: 20px;
                  padding: 15px;
                  margin-top: 35px;
                  border: 1px solid rgba(255, 255, 255, 0.2);
                  border-radius: 0.25rem;"
          >CONTACT SUPPORT</div>
        </a>
        `,
        icon: 'warning',
        showConfirmButton: false,
        showCloseButton: true,
      })
      .then((result) => {
        return result.isConfirmed;
      });
  }

  public pendingWalletWarning(): void {
    swalert.fire({
      title: `Request sent`,
      html: `Wallet change transaction submited.<br/>It will be changed after network confirmation.`,
      icon: 'success',
      showCancelButton: true,
      showConfirmButton: false,
      cancelButtonText: 'Close',
    });
  }

  public successClaimPopupSolana(): void {
    swalert.fire({
      title: `Claim Successful`,
      icon: 'success',
      showCancelButton: true,
      showConfirmButton: false,
      cancelButtonText: 'Close',
    });
  }

  public failureClaimPopupSolana(message: string): void {
    swalert.fire({
      title: `Failed to claim`,
      html: message,
      icon: 'error',
      showCancelButton: true,
      showConfirmButton: false,
      cancelButtonText: 'Close',
    });
  }

  public refundSubmittedPopup(): void {
    swalert.fire({
      customClass: {
        icon: 'request-pending',
      },
      title: 'Request Pending',
      html:
        'Refund request submitted. It will be processed in 72 hours and sent in USDT on the BNB Chain',
      iconHtml: `<img src="../../assets/images/Request_Pending.png" alt="success-icon"></img>`,
      confirmButtonText: 'Close',
    });
  }

  public async confirmRefundPopup(lock?: LockDTO): Promise<boolean> {
    let refundDaysText = '7 days';
    if (lock.refundDate) {
      const hours = (lock.refundDate - lock.startDate) / (1000 * 60 * 60);
      refundDaysText = hours < 72 ? `${hours.toFixed(0)} hours` : `${(hours / 24).toFixed(0)} days`;
    }
    var result = await swalert.fire({
      title: 'Request Refund',
      html: `You can ask a refund for all your distributions within ${refundDaysText} from the TGE. Should you change your mind, just claim the first distribution and your request will automatically be rejected. <br/> The refunds will be processed and sent in USDT on the BNB Chain within 72 hours after the TGE.`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: 'Request Refund',
    });

    return result.isConfirmed;
  }

  public alreadyRefunded(): any {
    return swalert
      .fire({
        title: 'Claim Tokens',
        html:
          'If you claim this distribution, your refund request will be rejected. Do you still want to claim?',
        icon: 'warning',
        showCancelButton: true,
        cancelButtonText: 'No',
        confirmButtonText: 'Yes',
      })
      .then((result) => {
        return result.isConfirmed;
      });
  }
}
