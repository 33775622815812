import { Component } from "@angular/core";
import { AlertService } from "../../services/alert.service";
import { ElrondService, IMultiSendItem } from "../../services/elrond.service";
import { environment } from "../../../environments/environment";

@Component({
    selector: "multi-sender",
    templateUrl: "./multi-sender.component.html",
    styleUrls: ["./multi-sender.component.scss"]
})
export class MultiSenderComponent {
    public tokenIdentifier: string = "CLT-37100d";
    public userAddressesToCount: string;
    public transactionHash: string;

    constructor(private readonly elrond: ElrondService, private readonly alertService: AlertService) {
    }

    get transactionUrl(): string {
        return `${environment.elrond.explorerUrl}/transactions/${this.transactionHash}`;
    }

    async sendTokens() {
        if (!this.tokenIdentifier) {
            this.alertService.show("Please set token identifier");
            return;
        }

        const addresses = this.parseAddresses();

        if (!addresses || addresses?.length === 0) {
            this.alertService.show("Please enter user addresses");
            return;
        }

        let txHash: string;

        try {
            if (!await this.elrond.signingProvider.isConnected()) {
                this.alertService.show("You must be connected to Elrond");
                return;
            }

            let account = await this.elrond.getCurrentAccount();
            this.transactionHash = await this.elrond.multiSend(account, "CLT-37100d", addresses);
        } catch (e) {
            this.alertService.show(e.toString());
        }
    }

    private parseAddresses(): IMultiSendItem[] {
        const lines = this.userAddressesToCount?.split("\n");
        if (!lines) {
            return [];
        }
        const result: IMultiSendItem[] = [];
        lines.map(l => {
            const temp = l.split(/[,;]/);
            if (temp.length == 2) {
                result.push({
                    address: temp[0].trim(),
                    amount: temp[1]
                });
            }
        });

        return result;
    }
}

