import {Injectable, NgZone} from "@angular/core";
import {Web3Service} from "./web3.service";
import {AuthService} from "./auth.service";
import {DEVNET, MAINNET, SolanaWeb3Service} from "./solana.service";
import {AlertService} from "./alert.service";
import Web3 from "web3";
import Wallet from '@project-serum/sol-wallet-adapter';
import {WalletProviders} from "../models/wallet/wallet-providers.enum";
import {environment} from "src/environments/environment";
import {ExtensionProvider} from "@elrondnetwork/erdjs-extension-provider/out";
import {Address, SignableMessage} from "@elrondnetwork/erdjs/out";
import { ElrondService } from "./elrond.service";
import { TonService } from "./ton.service";

declare let window: any;


@Injectable({ providedIn: 'root' })
export class WalletConnectorService {
  private providerName: string;

  constructor(
    private readonly web3Service: Web3Service,
    private alertService: AlertService,
    private readonly solanaWeb3Service: SolanaWeb3Service,
    private readonly elrond: ElrondService,
    private readonly ton: TonService,
    private readonly auth: AuthService,
    private readonly ngZone: NgZone,
  ) { }

  networkSolana: string =  environment.production ? MAINNET : DEVNET;

  public async connectWallet(walletProvider: WalletProviders) {
    switch (walletProvider) {
      case WalletProviders.Metamask:
        await this.connectMetamask();
        break;
      case WalletProviders.WalletConnect:
        await this.connectWalletConnect();
        break;
      case WalletProviders.Phantom:
        await this.connectPhantom();
        break;
      case WalletProviders.Solflare:
        await this.connectSolflare();
        break;
      case WalletProviders.Sollet:
        await this.connectSollet();
        break;
      case WalletProviders.Maiar:
        await this.connectMaiar();
        break;
      case WalletProviders.TonKeeper:
        await this.connectTonKeeper();
        break;
      case WalletProviders.TonConnect:
        await this.connectTonConnect();
        break;
      default:
        console.error('Unsupported wallet type');
    }
  }

  private async connectTonKeeper() {
    await this.ton.showTonKeeperDialog();
  }

  private async connectTonConnect() {
    await this.ton.showTonConnectDialog();
  }

  public async initTon() {
    this.ton.status$.subscribe(async status => { 
      console.log('TonConnectStatus', status);

      if (status.proof) {
        let authData = {
          timeStamp: status.proof.payload,
          tonAddress: this.ton.currentAccountValue,
          signature: status.proof.signature,
          tonProofRequest: status
        }

        await this.auth.authenticate(authData);
        location.reload();
      }
    });
    
    await this.ton.init();
  }

  private async connectMaiar(): Promise<void>{
    const provider = ExtensionProvider.getInstance();
    await provider.init();

    const address = await provider.login();

    console.log(provider.account);
    console.log(provider);
    this.elrond.initWallet(provider.account.address);

    //await this.signMessageForAuth(WalletProviders.Maiar, null, provider);
    this.auth.authenticateElrond();
  }

  private async connectMetamask(): Promise<void> {
    if (typeof window.ethereum == 'undefined') {
      this.alertService.show('MetaMask must be installed');
      return;
    }
    if (this.solanaWeb3Service.isConnected){
      console.warn('already connected with Solana');
      return;
    }
    const accountAddressPromise = window.ethereum.request({ method: 'eth_requestAccounts' });
    const networkPromise = window.ethereum.request({ method: 'eth_chainId' });

    await Promise.all([accountAddressPromise, networkPromise]).then(([account, network]) => {
      if (this.solanaWeb3Service.isConnected){
        console.warn('already connected with Solana');
        return;
      }
      this.web3Service.initWallet(account[0], network);
    });

    await this.signMessageForAuth('metamask');
  }

  public async initSolana() {
    const account = localStorage.getItem('accountAddressSolana');
    const network = localStorage.getItem('networkIdSolana');
    const walletType = localStorage.getItem('walletType');
    if (account && network) {
      if (walletType == 'solflare') {
        const solflare = (window as any).solflare;
        await solflare.connect();
        this.solanaWeb3Service.initWallet(account, network, solflare);
        return;
      }
      if (walletType == 'sollet') {
        const sollet = (window as any).sollet;
        let providerUrl = 'https://www.sollet.io';

        if (sollet){
          await this.initSollet(sollet, providerUrl, account, network);
        }
        else {
          setTimeout(async ()=>{
            const sollet = (window as any).sollet;
            await this.initSollet(sollet, providerUrl, account, network);
          }, 100);
        }
      }
      if (walletType == 'phantom') {
        setTimeout(async ()=>{
          const solana = (window as any).solana;
          await solana.connect();
          this.solanaWeb3Service.initWallet(account, network, solana);
        }, 100);
      }
    }
  }

  public async initSollet(sollet: any, providerUrl: any, account: any, network: any){
    let wallet = new Wallet(sollet, providerUrl);
    await wallet.connect();
    wallet.on('connect', (publicKey) => {
      this.ngZone.run(()=>{
        this.solanaWeb3Service.initWallet(account, network, wallet);
      })
    });

  }

  public async connectSollet() {
    try {
      const sollet = (window as any).sollet;
      let providerUrl = 'https://www.sollet.io';
      let wallet = new Wallet(sollet, providerUrl);
      await wallet.connect();
      localStorage.setItem('walletType', 'sollet');
      wallet.on('connect', async (publicKey) => {
        this.ngZone.run(async()=>{
          if (this.web3Service.isConnected){
            console.warn('already connected with EVM');
            return;
          }
          this.solanaWeb3Service.initWallet(publicKey.toBase58(), this.networkSolana, wallet);
          await this.signMessageForAuth('sollet', wallet);
        })
      });
    } catch (err) {
      console.log('err >>>>', err);
    }
  }

  public async connectPhantom() {
    try {
      const solana = (window as any).solana;
      const resp = await solana.connect();
      console.log('solana connect rest', resp);
      if (this.web3Service.isConnected){
        console.warn('already connected with EVM');
        return;
      }
      localStorage.setItem('walletType','phantom');
      this.solanaWeb3Service.initWallet(resp.publicKey.toString(), this.networkSolana, solana);
      console.log('solana init');
      await this.signMessageForAuth('phantom');

    } catch (err) {
      console.log('err >>>>', err);
      this.solanaWeb3Service.logout();
    }
  }

  public async connectSolflare() {
    try {
      const solflare = (window as any).solflare;
      await solflare.connect();
      if (this.web3Service.isConnected){
        console.warn('already connected with EVM');
        return;
      }
      localStorage.setItem('walletType', 'solflare');
      this.solanaWeb3Service.initWallet(window.solflare.publicKey.toString(), this.networkSolana, solflare);
      await this.signMessageForAuth('solflare');

    } catch (err) {
      console.log('err >>>>', err);
      this.solanaWeb3Service.logout();
    }
  }

  private async connectWalletConnect() {
    const data: any = await this.WalletConnect();
    if (this.solanaWeb3Service.isConnected){
      console.warn('already connected with Solana');
      return;
    }
    const chainIdDecimal = await this.web3Service.web3Instance.eth.getChainId();
    const chainId = `0x${chainIdDecimal.toString(16)}`;
    this.web3Service.initWallet(data[0], chainId, WalletProviders.WalletConnect);
    var dialog = this.alertService.show(`Please open "${this.providerName}" app and sign message.`);
    await this.signMessageForAuth('walletConnect');
    dialog.close();
  }


  private async signMessageForAuth(type: string, wallet?: Wallet, maiarProvider?: ExtensionProvider): Promise<void> {
    const timeStamp = Math.floor(Date.now() / 1000);
    let authData;
    let signedMessage;
    if (type == 'metamask' || type == 'walletConnect') {
      const signature = await this.web3Service.personalSign(timeStamp.toString(), this.web3Service.currentAccountValue);
      authData = {
        timeStamp,
        ethAddress: this.web3Service.currentAccountValue,
        signature,
      }
    } else if (type == WalletProviders.Maiar){
      const message = new SignableMessage({
        message: Buffer.from(timeStamp.toString()),
        address: Address.fromBech32(this.web3Service.currentAccountValue)
      });

      const sign = await maiarProvider.signMessage(message);

      authData = {
        timeStamp,
        solanaAddress: this.web3Service.currentAccountValue,
        signature: sign.signature.hex()
      }

    } else {
      const message = new TextEncoder().encode(timeStamp.toString());

      if (type == 'phantom') {
        const phantom = (window as any).solana;
        signedMessage = await phantom.signMessage(message, 'utf8');
      }

      if (type == 'solflare') {
        const solflare = (window as any).solflare;
        signedMessage = await solflare.signMessage(message, 'utf8');
      }

      if (type == 'sollet') {
        signedMessage = await wallet.sign(message, 'utf8');
      }

      const signature = this.solanaWeb3Service.toHexString(signedMessage.signature);

      authData = {
        timeStamp,
        solanaAddress: this.solanaWeb3Service.currentAccountValue,
        signature
      }
    }
    if (!authData.signature) {
      console.log('Not signed!');
      return;
    }
    await this.auth.authenticate(authData);
    location.reload();
  }


  async WalletConnect(): Promise<string[]> {
    //  Create WalletConnect Provider
    const provider = this.web3Service.createWalletConnectProvider();

    //  Enable session (triggers QR Code modal)
    let addresses = await provider.enable();
    this.providerName = provider.connector.peerMeta.name;

    //  Create Web3
    this.web3Service.web3Instance = new Web3(provider as any);

    return addresses;
  }

}
