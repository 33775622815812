import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';
import { MainComponent } from './components/main/main.component';
import { PipesModule } from './pipes/pipes.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { Guard } from './guards/auth.guard';
import { ApplyTokenInterceptor } from './interceptors/applyToken.interceptor';
import { ProjectInfoComponent } from './components/project-info/project-info.component';
import {CommonModule, DatePipe} from '@angular/common';
import { CreateClaimingComponent } from './components/create-claiming/create-claiming.component';
import { NgxSelectModule } from 'ngx-select-ex';
import { AdminGuard } from './guards/admin.guard';
import { DeployComponent } from './components/deploy/deploy.component';
import { SelectWalletComponent } from './components/dialogs/select-wallet/select-wallet.component';
import {MatDialogModule} from "@angular/material/dialog";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {DlgAlertComponent} from "./components/dialogs/dlg-alert.component";
import {AlertComponent} from "./components/dialogs/alert.component";
import {MatIconModule} from "@angular/material/icon";
import {AlertService} from "./services/alert.service";
import { EditClaimingComponent } from './components/edit-claiming/edit-claiming.component';
import { DocumentsComponent } from './components/footer/documents/documents.component';
import { MultiSenderComponent } from './components/multi-sender/multi-sender.component';
import {TextFieldModule} from "@angular/cdk/text-field";
import {CdTimerModule} from 'angular-cd-timer';
import { ProjectInfoResolver } from './components/project-info/project-info.resolver';
import { MatDatepickerModule } from '@angular/material/datepicker';
import {MatNativeDateModule} from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';
import {
  NgxMatDatetimePickerModule,
  NgxMatTimepickerModule
} from "@angular-material-components/datetime-picker";
import {MatButtonModule} from "@angular/material/button";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {MatSelectModule} from "@angular/material/select";
import {MatRadioModule} from "@angular/material/radio";
import {NGX_MAT_MOMENT_DATE_ADAPTER_OPTIONS, NgxMatMomentModule} from "@angular-material-components/moment-adapter";
import {MatPaginatorModule} from "@angular/material/paginator";
/*
import {
  NgxMatDatetimePickerModule,
  NgxMatNativeDateModule,
  NgxMatTimepickerModule
} from '@angular-material-components/datetime-picker';
*/

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    MainComponent,
    ProjectInfoComponent,
    CreateClaimingComponent,
    EditClaimingComponent,
    DeployComponent,
    SelectWalletComponent,
    DlgAlertComponent,
    AlertComponent,
    DocumentsComponent,
    MultiSenderComponent
  ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        FormsModule,
        PipesModule,
        BrowserModule,
        HttpClientModule,
        AppRoutingModule,
        NgxSelectModule,
        MatDialogModule,
        BrowserAnimationsModule,
        MatIconModule,
        TextFieldModule,
        CdTimerModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatInputModule,
        MatFormFieldModule,
        NgxMatTimepickerModule,
        MatButtonModule,
        NgxMatDatetimePickerModule,
        MatCheckboxModule,
        MatSelectModule,
        MatRadioModule,
        NgxMatMomentModule,
        MatPaginatorModule,
    ],
  providers: [
    Guard,
    AdminGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApplyTokenInterceptor,
      multi: true
    },
    AlertService,
    ProjectInfoResolver,
    DatePipe,
    { provide: NGX_MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
