import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { AuthModel } from '../models/auth.model';

import { JwtHelperService } from '@auth0/angular-jwt';
import { ApiService } from './api.service';

@Injectable({ providedIn: 'root' })
export class AuthService {
  public token: string;
  public activeUserAddress: string;

  private isElrondAuthenticated: boolean = false;
  private jwt: JwtHelperService;
  constructor(
    private readonly api: ApiService
  ) {
    this.jwt = new JwtHelperService();
    let token = localStorage.getItem('token');
    if (token) {
      if (this.isTokenExpired(token)) {
        this.logout(false);
        return;
      }
      this.token = token;
    }
  }

  public authenticate(model: AuthModel): Promise<any> {
    return this.api
      .authenticate(model)
      .pipe(
        tap((result: any) => {
          localStorage.setItem('token', result.accessToken);
          if (result.isAdmin) {
            localStorage.setItem('sAuth', result.isAdmin);
          } else {
            localStorage.removeItem('sAuth');
          }
          this.token = result.accessToken;
          console.log('auth');
        })
      )
      .toPromise();
  }

  public authenticateElrond(): void {
    this.isElrondAuthenticated = true;
  }

  public isAuthorized(): boolean {
    return (
      this.isElrondAuthenticated ||
      (this.token && !this.jwt.isTokenExpired(this.token))
    );
  }

  public isTokenExpired(token: string): boolean {
    return this.jwt.isTokenExpired(token);
  }

  public logout(reload: boolean = true): void {
    this.token = null;
    localStorage.removeItem('token');
    localStorage.removeItem('sAuth');
    if (reload) location.reload();
  }

  public isAdmin(): boolean {
    const isAdmin = localStorage.getItem('sAuth');
    return isAdmin !== null;
  }
}
