import {Component, OnInit} from '@angular/core';
import {MatDialogRef} from "@angular/material/dialog";
import {AlertService} from "../../../services/alert.service";
import { typesToWallets } from 'src/app/models/wallet/types-to-wallets';
import {IWalletType, WalletTypes} from "../../../models/wallet/wallet-type.model";
import {IWallet} from "../../../models/wallet/wallet";
import {WalletConnectorService} from "../../../services/wallet-connector.service";
import {WalletProviders} from "../../../models/wallet/wallet-providers.enum";
import {ActivatedRoute} from "@angular/router";
import {Web3Service} from "../../../services/web3.service";
import {SolanaWeb3Service} from "../../../services/solana.service";
import {ElrondService} from "../../../services/elrond.service";

@Component({
  selector: 'app-select-wallet',
  templateUrl: './select-wallet.component.html',
  styleUrls: ['./select-wallet.component.scss']
})
export class SelectWalletComponent implements OnInit {
  public selectedType: WalletTypes = WalletTypes.EVM;

  private isAdmin: boolean = false;

  public get typesToWallets(): IWalletType[] {
    return typesToWallets.filter(t=> !t.forAdmin || this.isAdmin);
  }

  public get availableWallets(): IWallet[] {
    return typesToWallets.filter(f => f.type == this.selectedType)[0].wallets;
  }

  constructor(public readonly walletConnector: WalletConnectorService,
              private readonly alertService: AlertService,
              private readonly route: ActivatedRoute,
              private readonly dialogRef: MatDialogRef<SelectWalletComponent>,
              private readonly web3: Web3Service,
              private readonly solana: SolanaWeb3Service,
              private readonly elrond: ElrondService) {
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(queryParams => {
      this.isAdmin = queryParams['isAdmin'] == 'true';
    });
  }

  public disconnect(): void {
    this.web3.logout();
    this.solana.logout();
    this.elrond.logout();
  }

  public async connectWallet(name: WalletProviders) {
    try {
      await this.walletConnector.connectWallet(name);
      this.dialogRef.close();
    } catch (e) {
      if (!e?.message?.includes('User denied message signature')
        && !e?.message?.includes('User closed modal')) {
        await this.alertService.show(`Unexpected error happened: ${e?.message}`);
      }
      this.disconnect();
    }
  }

  public selectWallet(type: WalletTypes){
    this.selectedType = type;
  }
}
