import { Injectable } from "@angular/core";
import { ApiService } from "./api.service";
import { Web3Service } from "./web3.service";
import { LockDTO } from "../dto/lock.dto";
import { PopupService } from "./popup.service";

@Injectable({ providedIn: 'root' })
export class ClaimService {
  constructor(private readonly api: ApiService, private readonly web3: Web3Service, private readonly popupService: PopupService) { }

  public async claimToken(lock: LockDTO): Promise<boolean> {
    let claimResult = false;
    try{
      console.log('lock.userTotalAllocation', lock.userTotalAllocation, lock.proof, lock.contractAddress, lock.version)
      const result = await this.web3.claim(lock.version, lock.userTotalAllocation, lock.contractAddress, lock.proof);
      if(result.transactionHash){
        this.popupService.successClaimPopup(lock);
        claimResult = true;
      }
      console.log('result', result);
    }
    catch(e){
      console.log('error', e);
    }
    return claimResult;
  }

  public createLockSolana(lock: LockDTO): Promise<any> {
    const response = this.api.createLock(lock);
    return response.toPromise();
  }

  public async createLock(lock: LockDTO): Promise<LockDTO> {
    const wallets = lock.claimingWallets.map(x => [x.address, x.amount]).sort((a, b) => {
      if (a[0] < b[0]) { return -1; }
      if (a[0] > b[0]) { return 1; }
      return 0;
    });
    const unlocks = lock.unlocks.map(x => ({
      startDate: x.startDate,
      totalPercentage: x.percentage,
      endDate: x.endDate,
      periodUnit: x.periodUnit,
      isUnlockedBeforeStart: x.isUnlockedBeforeStart
    }));

    const refundDateInSeconds = Math.floor(lock.refundDate / 1000);

    console.log("lock prepare", lock.tokenAddress, wallets, unlocks, refundDateInSeconds, lock.contractAddress);
    const txn = await this.web3.lock(lock.version, lock.tokenAddress, wallets, unlocks, refundDateInSeconds || 0, lock.contractAddress);
    console.log('txn =================>', txn);

    lock.transactionHash = txn.transactionHash;
    const response = this.api.createLock(lock);

    return response.toPromise<LockDTO>();
  }

  private getWalletArray(lock: LockDTO): string[][] {
    return lock.claimingWallets.map(x => [x.originalAddress || x.address, x.amount]).sort((a, b) => {
      if (a[0] < b[0]) {
        return -1;
      }
      if (a[0] > b[0]) {
        return 1;
      }
      return 0;
    });
  }
}
