import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

@Injectable()
export class ApplyTokenInterceptor implements HttpInterceptor {

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (!req.url.includes('api/')) {
            return next.handle(req);
        }
        else {
            return this.handleRequest(req, next);
        }
    }

    private handleRequest(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let authReq = req.clone({
            headers: req.headers
                .set('Authorization', `Bearer ${localStorage.getItem('token')}`)
                .set('X-Powered-By', "un.spike@gmail.com"),
        });
        return next.handle(authReq);
    }
}