import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import {Observable, of} from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthModel } from '../models/auth.model';
import { AuthDTO } from '../dto/auth.dto';
import { PlatformDTO } from "../dto/platform.dto";
import { LockDTO } from "../dto/lock.dto";
import {filter, map} from 'rxjs/operators';
import { WalletDTO } from "../dto/wallet.dto";
import { UpdateLockDTO } from '../dto/update-lock.dto';
import {LockDataDto} from "../dto/lock-data.dto";
import { Blockchain } from '../enums/blockchain_enum';

@Injectable({ providedIn: 'root' })
export class ApiService {
    private getActionUrl(action: string): string {
        return `${environment.apiUrl}${action}`;
    }

    constructor(private readonly httpClient: HttpClient) { }

    public authenticate(model: AuthModel): Observable<AuthDTO> {
        return this.httpClient.post<AuthDTO>(this.getActionUrl('/api/account/authenticate'), model)
    }

    public getPlatforms(): Observable<PlatformDTO[]> {
        return this.httpClient.get<PlatformDTO[]>(this.getActionUrl('/api/platforms'));
    }

    public getLocks(blockchain:Blockchain, networkId?: string): Observable<LockDTO[]> {
        const wtf = parseInt(networkId);
        return this.httpClient.get<LockDTO[]>(this.getActionUrl(`/api/locks/with_unlocks?blockChainId=${blockchain}&networkId=${wtf ? wtf: '-1'}`))
          .pipe(map(items => items.map(i=>this.mapToLockDTO(i))));
    }

    public getLocksData(): Observable<LockDataDto[]> {
      return this.httpClient.get<LockDataDto[]>(this.getActionUrl('/api/locks/data'))
        .pipe(map(items => items.map(i=>this.mapToLockDataDTO(i))));
    }

    public getLock(id: number): Observable<LockDTO> {
        return this.httpClient.get<LockDTO>(this.getActionUrl(`/api/locks/${id}`))
          .pipe(map(i => this.mapToLockDTO(i)));
    }

    public createLock(model: LockDTO): Observable<any> {
        return this.httpClient.post(this.getActionUrl('/api/locks'), model);
    }

    public updateLock(model: UpdateLockDTO): Observable<any> {
        return this.httpClient.put(this.getActionUrl('/api/locks'), model);
    }

    public deleteLock(id: number): Observable<any> {
        return this.httpClient.delete(this.getActionUrl(`/api/locks/${id}`));
    }

    public updateWallet(id: number, model: WalletDTO): Observable<any> {
        return this.httpClient.put(this.getActionUrl(`/api/locks/${id}/wallets`), model);
    }

    public getProofs(lockId:number): Observable<string[]>{
        return this.httpClient.get<string[]>(this.getActionUrl(`/api/locks/get_proof?lockId=${lockId}`));
    }

    public getWallets(lockId:number): Observable<any[]>{
        return this.httpClient.get<any[]>(this.getActionUrl(`/api/locks/wallets?lockId=${lockId}`));
    }

    private mapToLockDTO(item: any): LockDTO {
      return Object.assign(new LockDTO(), item);
    }

    private mapToLockDataDTO(item: any): LockDataDto {
      return Object.assign(new LockDataDto(), item);
    }
    
}
