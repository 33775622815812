import {Observable, Subject} from "rxjs";
import {Injectable, NgZone, OnDestroy} from "@angular/core";
@Injectable({providedIn: 'root'})
export class EventsHandler implements OnDestroy {
  private accountChange: Subject<string> = new Subject<string>();
  public accountChange$: Observable<string> = this.accountChange.asObservable();

  private networkChange: Subject<string> = new Subject<string>();
  /*
   * Observable for chain changing events in hex (like '0x16')
   */
  public networkChange$: Observable<string> = this.networkChange.asObservable();

  constructor(private readonly ngZone :NgZone){}

  ngOnDestroy(): void {
    this.accountChange?.complete();
    this.networkChange?.complete();
  }

  public addProviderEvents(provider: any) {
    provider.on('accountsChanged', (accounts: string[]) => {
      this.ngZone.run(()=>this.accountChange.next(accounts[0]));
    });

    provider.on('chainChanged', (chainId: number | string) => {
      this.ngZone.run(()=>this.networkChange.next(typeof chainId === 'number' ? `0x${chainId.toString(16)}` : chainId));
    });
  }
}
