import { environment } from "src/environments/environment";
import { Injectable } from "@angular/core";
import {
    Account, Address, AddressValue, ContractFunction, SmartContract, StringValue, TokenIdentifierValue, TypedValue,
    U64Value
} from "@elrondnetwork/erdjs/out";
import { ProxyNetworkProvider } from "@elrondnetwork/erdjs-network-providers/out";
import { ExtensionProvider } from "@elrondnetwork/erdjs-extension-provider/out";
import BigNumber from "bignumber.js";
import { BehaviorSubject } from "rxjs";
import { AuthService } from "./auth.service";

export interface IMultiSendItem {
    address: string,
    amount: string
}

@Injectable({ providedIn: "root" })
export class ElrondService {
    public multiSenderMethod = "sendMultipleEsdt";
    public multiSenderContract: SmartContract;
    public networkProvider: ProxyNetworkProvider;
    public isConnected: boolean = false;
    private readonly currentAccount = new BehaviorSubject<string>('');
    public readonly currentAccount$ = this.currentAccount.asObservable();

    public get signingProvider(): ExtensionProvider {
        return ExtensionProvider.getInstance();
    }

    constructor(private readonly authService: AuthService) {
        this.networkProvider = new ProxyNetworkProvider(environment.elrond.proxyUrl);
        this.multiSenderContract = new SmartContract({ address: new Address(environment.elrond.multiSenderAddress) });
    }

    public initWallet(address: string) {
        this.isConnected = true;
        this.currentAccount.next(address);
    }

    public logout(): void {
        this.authService.logout();
        this.isConnected = false;
        this.currentAccount.next('');
    }

    public async getCurrentAccount(): Promise<Account> {
        let address = new Address(this.currentAccount.value);
        let account = new Account(address);

        let accountOnNetwork = await this.networkProvider.getAccount(address);
        account.update(accountOnNetwork);

        return account;
    }

    public async multiSend(account: Account, tokenId: string, items: IMultiSendItem[]): Promise<string> {
        const contract = new SmartContract({ address: new Address(environment.elrond.multiSenderAddress) });
        const networkConfig = await this.networkProvider.getNetworkConfig();

        let args: TypedValue[] = [
            new TokenIdentifierValue(tokenId),
            new U64Value(this.calcTotalAmount(items)),
            new StringValue(this.multiSenderMethod),
            new TokenIdentifierValue(tokenId)
        ];

        items.forEach(x => {
            args.push(new AddressValue(new Address(x.address)));
            args.push(new U64Value(new BigNumber(x.amount)));
        });

        let tx = contract.call({
            func: new ContractFunction("ESDTTransfer"),
            chainID: networkConfig.ChainID,
            gasLimit: 5000000,
            args
        });

        tx.setNonce(account.nonce);
        await this.signingProvider.signTransaction(tx);

        return await this.networkProvider.sendTransaction(tx);
    }

    public calcTotalAmount(items: IMultiSendItem[]) {
        const total = items
            .map(x => new BigNumber(x.amount))
            .reduce((a, b) => a.plus(b), new BigNumber(0));

        return total.toFixed();
    }
}
