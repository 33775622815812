import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';

@Pipe({
  name: 'chainName',
})
export class ChainNamePipe implements PipeTransform {
  transform(id: number): string | Observable<string> {
    switch (id) {
      case 1:
      case 11155111:
        return 'Ethereum';
      case 56:
      case 97:
        return 'BNB Chain';
      case 80002:
      case 137:
        return 'Polygon';
      case 43113:
      case 43114:
        return 'Avalanche';
      case 321:
        return 'KCC';
      case 322:
        return 'KCC';
      case 42161:
      case 421614:
        return 'Arbitrum';
      case 8453:
      case 84532:
        return 'Base';
      case 81457:
      case 168587773:
        return 'Blast';
      case 9999:
        return 'Solana';
      default:
        return 'Unknown';
    }
  }
}
