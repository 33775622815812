import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CreateClaimingComponent } from './components/create-claiming/create-claiming.component';
import { MainComponent } from './components/main/main.component';
import { ProjectInfoComponent } from './components/project-info/project-info.component';
import { DeployComponent } from './components/deploy/deploy.component';
import { AdminGuard } from './guards/admin.guard';
import { EditClaimingComponent } from './components/edit-claiming/edit-claiming.component';
import {DocumentsComponent} from "./components/footer/documents/documents.component";
import {MultiSenderComponent} from "./components/multi-sender/multi-sender.component";
import { ProjectInfoResolver } from './components/project-info/project-info.resolver';

const routes: Routes = [
  { path:"", component: MainComponent },
  { path:"platform/:platform/:projectname/:id", component: ProjectInfoComponent, resolve: { lockInfo: ProjectInfoResolver }},
  { path:"create-claiming", component: CreateClaimingComponent, canActivate: [AdminGuard]},
  { path:"deploy", component: DeployComponent, canActivate: [AdminGuard]},
  { path:"documents/:type", component: DocumentsComponent},
  { path:"multi-sender", component: MultiSenderComponent},
  { path:"edit-claiming/:id", component: EditClaimingComponent, canActivate: [AdminGuard]}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
