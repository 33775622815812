import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "sort"
})
export class ArraySortPipe  implements PipeTransform {
  transform(input: [], sortKey?: string, descSort?: boolean, numSort?: boolean): any[] {
    const result = [...input].sort((a: any, b: any) => {
      if(!a || !b || !sortKey) return 0;
      //console.log(a, sortKey, a[sortKey], b[sortKey], typeof a[sortKey] === "bigint");
      let aValue = typeof a === 'object'
        ? sortKey.includes('|') 
          ? a[sortKey.split('|')[0]][sortKey.split('|')[1]] 
          : a[sortKey]
        : a;
      let bValue = typeof b === 'object'
        ? sortKey.includes('|') 
          ? b[sortKey.split('|')[0]][sortKey.split('|')[1]] 
          : b[sortKey]
        : b;

      if (numSort)
      {
        try {
          aValue = BigInt(aValue);
        }
        catch {
          aValue = BigInt(0)
        }

        try {
          bValue = BigInt(bValue);
        }
        catch {
          bValue = BigInt(0)
        }
      }  

      if (aValue < bValue) {
        return !descSort ? -1 : 1;
      } else if (aValue > bValue) {
        return !descSort ? 1: -1;
      }

      return 0;
    });
    //console.log('result', result);
    return result;
  }
}