import { NgModule } from '@angular/core';
import { FilterPipe } from './filter.pipe';
import { KeysPipe } from './objectkeys.pipe';
import { ShiftDecimals } from './shiftDecimals.pipe';
import { ShortAddressPipe } from './shortAddress.pipe';
import { ChainNamePipe } from './chainName.pipe';
import { ChainIconPipe } from "./chainIcon.pipe";
import { ArraySortPipe } from './arraySort.pipe';

@NgModule({
    exports: [
        ShortAddressPipe,
        KeysPipe,
        ShiftDecimals,
        FilterPipe,
        ChainNamePipe,
        ChainIconPipe,
        ArraySortPipe
    ],
    declarations: [
        ShortAddressPipe,
        KeysPipe,
        ShiftDecimals,
        FilterPipe,
        ChainNamePipe,
        ChainIconPipe,
        ArraySortPipe
    ],
    providers: [],
})
export class PipesModule {}
