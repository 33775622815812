export enum Blockchain
{
    All = -1,
    Ethereum = 0,
    Binance = 1,
    Polygon = 2,
    Solana = 3,
    Avalanche = 4,
    Arbitrum = 5,
    Base = 6,
    Blast = 7,
    Ton = 8
}