import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from "@angular/router";
import { Observable } from "rxjs";
import { ApiService } from "src/app/services/api.service";

@Injectable()
export class ProjectInfoResolver implements Resolve<Observable<any>> {

  constructor(private readonly apiService: ApiService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    const projectId = route.params['id'];
    console.log('resolve', projectId);
    return this.apiService.getLock(projectId);
  }
}