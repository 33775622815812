<div *ngIf="title" class="modal-header">
  <h5 class="modal-title">{{ title }}</h5>
</div>
<div class="modal-body alert-template">
  <mat-icon *ngIf='icon'>{{ icon }}</mat-icon>

  <div *ngIf="message"
       class="text-center small-text mb-4">{{ message }}</div>

  <div *ngIf="innerHTML"
       [innerHTML]="innerHTML"></div>

  <button mat-raised-button
          color="primary"
          class="w-100"
          (click)='onContinue()'>
    {{ buttonOkLabel || 'Ok' }}
  </button>
</div>
