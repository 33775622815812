import { Injectable, NgZone } from "@angular/core";
import { environment } from "src/environments/environment";
import { isWalletInfoCurrentlyInjected, TonConnect, TonConnectUI, toUserFriendlyAddress } from "@tonconnect/ui";
import { AuthService } from "./auth.service";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { TonConnectStatus } from "../models/ton-connect-status";
import { AlertService } from "./alert.service";

@Injectable({ providedIn: 'root' })

export class TonService {
    constructor(private readonly ngZone: NgZone, private readonly authService: AuthService, private readonly alertService: AlertService,) {}

    ui: TonConnectUI;
    public isConnected: boolean = false;

    private readonly currentAccount = new BehaviorSubject<string>('');
    public readonly currentAccount$ = this.currentAccount.asObservable();

    private readonly currentNetwork = new BehaviorSubject<string>('');
    public readonly currentNetwork$ = this.currentNetwork.asObservable();

    public get currentAccountValue(): string {
        return this.currentAccount.value;
    };

    public get currentNetworkValue(): string {
        return this.currentNetwork.value;
    }

    private statusSubject: Subject<TonConnectStatus> = new Subject<TonConnectStatus>();
    public status$: Observable<TonConnectStatus> = this.statusSubject.asObservable();

    public async init(): Promise<boolean> {
        this.ui =  new TonConnectUI({
            manifestUrl: environment.ton.manifestUrl,
            buttonRootId: 'ton-connect'
        });

        this.ui.onStatusChange(wallet => {
            this.isConnected = wallet?.account ? true : false;
            this.setAccount(toUserFriendlyAddress(wallet?.account?.address, environment.ton.testnet));
            this.setNetwork(wallet?.account?.chain);

            let proof = (wallet.connectItems?.tonProof as any)?.proof;
            if (proof) {
                proof.state_init = wallet.account.walletStateInit;
            }

            this.statusSubject.next({ 
                connected: this.isConnected, 
                account: this.currentAccount.value, 
                address: wallet?.account?.address,
                network: this.currentNetwork.value,
                public_key: wallet.account.publicKey,
                proof: proof
            });
        }); 

        if (localStorage.getItem("ton-connect-ui_wallet-info")) {
            await this.ui.connector.restoreConnection();
            return true;
        }

        return false;
    }

    public async showTonKeeperDialog() {
        const timeStamp = Math.floor(Date.now() / 1000);

        const wallets = await TonConnect.getWallets();
        const injectedWallets = wallets.filter(isWalletInfoCurrentlyInjected);
        const tonKeeperWallet = injectedWallets.find(x => x.appName == "tonkeeper");

        if (!tonKeeperWallet) {
            this.alertService.show("TonKeeper must be installed");
            return;
        }

        const universalLink = this.ui.connector.connect(
            { jsBridgeKey: tonKeeperWallet.jsBridgeKey }, 
            { tonProof: timeStamp.toString()}
        );
    }

    public async showTonConnectDialog() {
        const timeStamp = Math.floor(Date.now() / 1000);

        this.ui.setConnectRequestParameters({
            state: "ready",
            value: { tonProof: timeStamp.toString() }
        });

        await this.ui.openModal();
    }

    public logout(): void {
        this.authService.logout();
        this.ui.disconnect();
        this.setAccount(null);
        this.setNetwork(null);
    }

    private setAccount(account: string): void {
        if (account) {
            this.ngZone.run(() => {
                this.currentAccount.next(account);
                localStorage.setItem('tonAccount', account);
            });
        }
        else {
            localStorage.removeItem('tonAccount');
        }
    }

    private setNetwork(networkId: string): void {
        if (networkId) {
            this.ngZone.run(() => {
                this.currentNetwork.next(networkId);
                localStorage.setItem('tonNetwork', networkId);
            })
        }
        else {
            localStorage.removeItem('tonNetwork');
        }
    }
}