<header>
    <div class="container">
        <div class="head-left">
            <div class="logo">
                <a href="#"><img src="assets/images/logo.png" alt=""></a>
            </div>
        </div>

        <div class="head-right" data-da=".heads,1100,1">

            <a routerLink="/create-claiming" *ngIf="isNewLockAvailable"  class="nw_lock">New Lock <img src="assets/images/nw_lock.png" alt=""></a>
            <!-- <a href="#">Apply IDO</a> -->
            <a class="open_arrow" [ngClass]="{'open_arrow_up': show}" *ngIf="isConnected" (click)="toggleNetworkChange($event)">
                <span><img style="margin-right: 6px;" width="28" height="28" [src]="currentNetwork | async | chainIcon"/>  {{currentNetwork | async | chainName  }} </span>
            </a>
            <div class="dropdown-content-networks" [ngClass]="{'show': show}" >
              <a (click)="changeNetwork(1)"><span><img style="margin-right: 6px;" width="28" height="28" [src]="1 | chainIcon"/>  {{1 | chainName  }} </span></a>
              <a (click)="changeNetwork(56)"><span><img style="margin-right: 6px;" width="28" height="28" [src]="56 | chainIcon"/>  {{56 | chainName  }} </span></a>
              <a (click)="changeNetwork(137)"><span><img style="margin-right: 6px;" width="28" height="28" [src]="137 | chainIcon"/>  {{137 |  chainName  }} </span></a>
              <a (click)="changeNetwork(43114)"><span><img style="margin-right: 6px;" width="28" height="28" [src]="43114 | chainIcon"/>  {{43114 | chainName  }} </span></a>
              <a (click)="changeNetwork(321)"><span><img style="margin-right: 6px;"  height="28" [src]="321 | chainIcon"/>  {{321 | chainName  }} </span></a>
              <a (click)="changeNetwork(42161)"><span><img style="margin-right: 6px;"  height="28" [src]="42161 | chainIcon"/>  {{42161 | chainName  }} </span></a>
              <a (click)="changeNetwork(8453)"><span><img style="margin-right: 6px;"  height="28" [src]="8453 | chainIcon"/>  {{8453 | chainName  }} </span></a>
              <a (click)="changeNetwork(81457)"><span><img style="margin-right: 6px;"  height="28" [src]="81457 | chainIcon"/>  {{81457 | chainName  }} </span></a>
            </div>

            <a class="open_au" *ngIf="isConnected" (click)="disconnect()">
                <span>{{currentAccount | async | shortAddress}}</span>
            </a>
            <a class="open_au" *ngIf="isSolanaConnected" (click)="disconnect()">
                <span>{{currentSolanaAccount | async | shortAddress}}</span>
            </a>
            <a class="open_au" *ngIf="isElrondConnected" (click)="disconnect()">
                <span>{{currentElrondAccount | async | shortAddress}}</span>
            </a>
            <a class="open_au" *ngIf="isTonConnected" (click)="disconnect()">
                <span>{{currentTonAccount | async | shortAddress}}</span>
            </a>
            <div [hidden]="true" class="wallet-provider"> 
                <div id="ton-connect"></div>
              </div>
            <a class="connect_wallet" *ngIf="!(isConnected || isSolanaConnected || isElrondConnected || isTonConnected)" (click)="connectWallet()">Connect Wallet <img src="/assets/images/Wallet.png" alt=""></a>
        </div>

        <div class="menu_btn_mobil">
            <span></span>
            <span></span>
            <span></span>
        </div>
    </div>

    <div class="heads">
        <div class="heads_off">
            <img src="assets/images/Close.png" alt="">
        </div>
    </div>
</header>
