import {Pipe, PipeTransform} from '@angular/core';
import {BigNumber} from 'bignumber.js';

@Pipe({
  name: 'shiftDecimals',
})
export class ShiftDecimals implements PipeTransform {
  transform(value: string, shiftedBy: number, fractionDigits: number = 4): string {
    const hasFraction: boolean = fractionDigits > 0;
    const regExp = hasFraction
      ? /\B()(?=(\d{3})+(?!\d)\.)/g
      : /\B()(?=(\d{3})+(?!\d))/g;

    if (value) {
      return new BigNumber(value).shiftedBy(shiftedBy).toNumber().toFixed(fractionDigits).toString().replace(regExp, " ");
    }
    return '0';
  }
}
