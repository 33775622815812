import {IWallet} from "./wallet";


export enum WalletTypes {
  EVM = 'EVM',
  Solana = 'Solana',
  Elrond = 'Elrond',
  TON = 'TON'
}

export interface IWalletType {
  readonly type: WalletTypes;
  readonly wallets: IWallet[];
  readonly forAdmin?: boolean;
}


